import { render, staticRenderFns } from "./menu-item-link.vue?vue&type=template&id=90a16902&scoped=true&"
import script from "./menu-item-link.vue?vue&type=script&lang=js&"
export * from "./menu-item-link.vue?vue&type=script&lang=js&"
import style0 from "./menu-item-link.vue?vue&type=style&index=0&id=90a16902&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90a16902",
  null
  
)

export default component.exports