<template>
  <span></span>
</template>
<script>
function console_log(msg) {
  //console.log((new Date().toISOString())+" "+msg);
}

export default {
  name: "UserInteractionMonitor",
  data() {
    return {
      active: false,
      events: []
    };
  },
  computed: {
    timeout() {
      return (
        this.logoffTimeout ??
        this.$root.config.user_interaction?.timeout ??
        60000
      );
    },
    logoffTimeout() {
      return this.$store.getters["user/authPolicies"]?.auto_logout_after_hours
        ? this.$store.getters["user/authPolicies"]?.auto_logout_after_hours *
            3600000
        : null;
    }
  },
  watch: {
    timeout: "reset"
  },
  methods: {
    userInteraction(i) {
      if (i != this.active) {
        this.active = i;
        console_log("User " + (this.active ? "active" : "inactive"));
        this.$emit("userInteraction", i);
      }
    },
    monitor(events) {
      if (events) {
        for (var i in events) {
          window.addEventListener(events[i], this.userActivityThrottler);
        }
        this.events = events;
      } else {
        for (var i in this.events) {
          window.removeEventListener(
            this.events[i],
            this.userActivityThrottler
          );
        }
      }
    },
    reset() {
      clearTimeout(this._timer_inactivity);
      this._timer_inactivity = setTimeout(() => {
        this.userInteraction(false);
      }, this.timeout);
    },
    userActivityThrottler() {
      this.userInteraction(true);
      if (!this._timer_interaction) {
        this._timer_interaction = setTimeout(() => {
          this.reset();
          clearTimeout(this._timer_interaction);
          this._timer_interaction = null;
        }, 1000);
      }
    }
  },
  created() {
    this._timer_interaction = null;
    this._timer_inactivity = null;
    if (this.timeout) {
      let events =
        this.$root.config.user_interaction &&
        this.$root.config.user_interaction.events &&
        this.$root.config.user_interaction.length
          ? this.$root.config.user_interaction
          : ["mousemove", "keydown"];
      this.monitor(events);
    } else {
      this.userInteraction(true);
    }
  },
  beforeDestroy() {
    this.monitor();
    clearTimeout(this._timer_inactivity);
    clearTimeout(this._timer_interaction);
  }
};
</script>
