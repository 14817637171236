<template>
  <router-link v-bind:to="href" :class="{ 'has-portal-icon': isPortalIcon }">
    <Icon :name="option.icon" />
    <span>{{ option.label }}</span>
    <span
      class="pull-right-container"
      :class="{ rotated: showOptions }"
      v-if="multilevel && (menuExpanded || subtree)"
      @click.prevent="toggleOptions"
    >
      <i class="fa fa-angle-left pull-right"></i>
    </span>
  </router-link>
</template>

<script>
import Icon from "@/components/icons/icon";

export default {
  name: "MenuItemPanel",
  components: { Icon },
  props: {
    option: {
      type: Object,
      required: true
    },
    multilevel: {
      // controls if it will handle hide/show of other level
      type: Boolean,
      required: false,
      default: false
    },
    subtree: {
      // controls if it's inside another level to decide if arrow icon is shown
      type: Boolean,
      required: false,
      default: false
    },
    equipmentId: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      menuExpanded: false,
      showOptions: false
    };
  },
  computed: {
    href() {
      let search = "/:equipmentId";
      if (this.option.href.endsWith(search) && this.equipmentId != 0)
        return this.option.href.replace(search, "/" + this.equipmentId);
      else return this.option.href.replace(search, "");
    },
    isPortalIcon() {
      return this.option.icon.startsWith("portal-icon");
    }
  },
  methods: {
    toggleOptions() {
      this.showOptions = !this.showOptions;
      this.$emit("toggleTreeview");
    }
  },
  mounted() {
    if (!this.subtree)
      $(document)
        .on("expanded.pushMenu", () => (this.menuExpanded = true))
        .on("collapsed.pushMenu", () => (this.menuExpanded = false));
  }
};
</script>
<style scoped>
.pull-right-container {
  transition: transform 500ms;
  transform-origin: 42% center;
}

.pull-right-container.rotated {
  transform: rotate(-90deg);
}

.has-portal-icon {
  display: inline-flex;
  align-items: center;
  gap: 6px;
}
</style>
